.main-app {
  margin: 0;
  padding: 0;
  width: 100%;
  overflow-x: hidden;
}

.main-app-body {
  margin: 0;
  padding: 0;
  width: 100%;
}

.full-screen-section {
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  padding: 1rem 2rem;
}

.greeting-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  padding: 7rem 2rem 2rem 2rem;
}
.bio-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  padding: 0 0 5rem 0;
}

.contact-me-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  max-width: 724px;
  margin: 0 auto;
  padding: 7rem 2rem;
}

.projects-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0;
  padding: 7rem 2rem;
}

.projects-section-grid {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  max-width: 100rem;
  gap: 2rem 1rem;
}

.project-card {
  width: 400px;
  max-width: 400px;
  border-radius: 2px;
  background-color: #fff;
  cursor: pointer;
  transition: transform 0.2s;
}

@media (max-width: 1000px) {
  .full-screen-section {
    padding: 0;
  }
  .contact-me-section {
    padding: 5rem 0;
  }
  .projects-section {
    padding: 5rem 0;
  }
  .projects-section-grid {
    gap: 2rem 0.7rem;
  }
  .project-card {
    width: 375px;
  }
}

@media (max-width: 750px) {
  .contact-me-section {
    max-width: 90%;
  }
  .projects-section {
    max-width: 100%;
    width: 100%;
  }
  .projects-section-grid {
    gap: 2rem 0;
    width: 100%;
    max-width: 100%;
  }
  .project-card {
    max-width: 350px;
    width: 100%;
  }
  .greeting-box {
    gap: 1rem 0.5rem;
    padding: 7rem 1rem 1rem 1rem;
  }
}

@media (max-width: 500px) {
  .contact-me-section {
    max-width: 85%;
  }
  .projects-section {
    max-width: 100%;
    width: 100%;
    padding: 3rem 0;
  }
  .projects-section-grid {
    width: 90%;
    max-width: 90%;
    gap: 2rem 0;
  }
  .project-card {
    max-width: 350px;
    width: 100%;
  }
  .greeting-box {
    gap: 1rem 0.5rem;
    padding: 7rem 0.5rem 0.5rem 0.5rem;
  }
}

@keyframes chatbotHighlight {
  0% {
    box-shadow: 0 0 0px rgba(254, 254, 254, 0.6);
  }
  50% {
    box-shadow: 0 0 2rem rgba(255, 152, 234, 0.633);
  }
  100% {
    box-shadow: 0 0 0px rgba(222, 137, 255, 0.778);
  }
}

.pulse-highlight {
  animation: chatbotHighlight 3s ease-in-out infinite;
}

@keyframes typingText {
  0% {
    content: "ask me";
  }
  33% {
    content: "about Andrew's";
  }
  66% {
    content: "career";
  }
  100% {
    content: "ask me";
  }
}

.typing-icon .typing-text {
  font-size: 0.2rem;
  opacity: 1;
  transition: opacity 1.5s ease-in-out;
}
